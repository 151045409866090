<template>
  <div class="page">
    <div class="title">
      奉化区未来社区智慧服务平台<img
        src="./img/logout.png"
        @click="goBack"
        class="goBack"
      />
    </div>
    <div class="black_layer" v-if="show" @click="show = false">
      <div class="box">
        <img :src="iconUrl" alt="" />
      </div>
    </div>
    <div class="box">
      <div class="list" v-for="(item, index) in list" :key="index">
        <div
          @mousemove="inBox(item2, index2)"
          @mouseleave="outBox(item2, index2)"
          @click="toDetail(item2)"
          class="out_box"
          v-for="(item2, index2) in item"
          :key="index2"
        >
          <div
            class="mask"
            @click.stop="error"
            v-if="item2.isSelected == 0"
          ></div>
          <div class="item" :ref="item2.guideConfig.code">
            <img :src="item2.guideConfig.icon" alt="" />
            <div>{{ item2.guideConfig.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { guideList } from "./api.js";
import { Message } from "element-ui";
import { mapState } from "vuex";
import { AESEcbEncrypt, AESEcbDecrypt } from "@/utils/common";
export default {
  name: "guide",
  data() {
    return {
      iconUrl: "",
      show: false,
      menu: [],
      urlData: null,

      list: [[], []],
    };
  },
  computed: {
    ...mapState("app", ["menuLimit"]),
  },
  created() {
    console.log(this.menuLimit, 1564654);
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    goBack() {
      this.$router.replace({
        name: "login",
      });
    },
    error() {
      Message.error("暂无权限！！！");
    },
    //获取所有功能模块list
    async getList() {
      let params = {
        roleId: JSON.parse(localStorage.getItem("userInfo")).roleId,
      };
      let res = await this.$axios.get(`${guideList}`, { params: params });
      if (res.code === 200) {
        res.data.forEach((item) => {
          if (item.guideConfig.code == "main") {
            this.list[0].unshift(item);
          }
          if (item.guideConfig.code == "base") {
            this.list[1].push(item);
          }
        });
        this.list[0].sort((a, b) => b.guideConfig.sort - a.guideConfig.sort);
        let logo = {
          guideConfig: {
            code: "main",
            icon: "https://fenghua.zhuneng.cn/imgForManager/static/index/portal-icon/mingzhu.png",
            key: "mingzhu",
            name: "掌上奉化",
            sort: 1,
          },
          id: null,
          isSelected: 1,
          platformConfig: {},
          type: null,
        };
        this.list[0].unshift(logo);
        console.log(this.list[0]);
      }
    },
    findUrl(oList) {
      console.log(oList, 4567);
      if (oList[0] && oList[0].url) {
        this.urlData = oList[0];
        return "";
      } else if (oList[0] && oList[0].url.length == 0) {
        if (!oList[0].children) {
          this.urlData = oList[0];
          return "";
        } else {
          this.findUrl(oList[0].children);
        }
      } else {
        console.log(oList[0].children, 4567);
        this.findUrl(oList[0].children);
      }
      console.log(this.urlData, "this.urlData");
    },
    //获取右侧菜单
    async getMenu(name, type) {
      let params = {
        roleId: JSON.parse(localStorage.getItem("userInfo")).roleId,
        userId: JSON.parse(localStorage.getItem("userInfo")).userId,
      };
      let url = `/gateway/blade-system/menu/routes`;
      if (this.menuLimit.name && this.menuLimit.type) {
        url = `/gateway/blade-system/menu/routes?domain=${this.menuLimit.type}`;
      }
      let res = await this.$axios.post(url, params);
      if (res.code === 200) {
        this.menu = res.data;
      }
    },
    //跳转详情
    async toDetail(item) {
      if (item.type == 1) {
        // 管理后台/物联网引擎/数据仓引擎（内部跳转/路由跳转）
        let name = item.guideConfig.key;
        let type = item.platformConfig.domain;
        let menuLimit = {
          name,
          type,
        };
        console.log(menuLimit, "99999999999");
        sessionStorage.setItem("menuLimit", JSON.stringify(menuLimit));
        this.$store.commit("app/setMenuLimit", menuLimit);
        await this.getMenu(name, type);
        this.findUrl(this.menu);
        sessionStorage.setItem("logoName", item.platformConfig.name);

        if (this.urlData.alias == "third-backend") {
          this.$router.push({
            name: "oIframe",
            query: {
              path: this.urlData.path,
              modelKey: this.urlData.modelKey,
              fromId: this.urlData.id,
            },
          });
        } else {
          if (this.urlData) {
            this.$router.push({
              name: this.urlData.url,
            });
          }
        }
      } else if (item.type == 2) {
        // 驾驶舱/三维资产引擎（总部提供地址/携带加密后token跳转）
        let token1 = JSON.parse(localStorage.getItem("userInfo")).accessToken;
        let token = encodeURIComponent(AESEcbEncrypt(`${token1}`));
        console.log(`${item.platformConfig.url}&token=${token}`);
        window.open(`${item.platformConfig.url}&token=${token}`);
        // window.location.href = `${item.platformConfig.url}&token=${token}`;
      } else if (item.type == 3) {
        //应用能力中心（开发平台/额外打开浏览器标签页常规跳转）
        window.open(item.platformConfig.url);
      } else if (item.guideConfig.key == "mingzhu") {
        //掌上奉化
        this.show = true;
        this.iconUrl = item.guideConfig.icon;
      }
    },
    // 鼠标移入移出事件
    inBox(item2, index2) {
      if (item2.guideConfig.key == "mingzhu" && item2.isSelected == 1) {
        this.$refs[item2.guideConfig.code][index2].style.transition = "0.15s";
        this.$refs[item2.guideConfig.code][index2].style.transform =
          "scale(1.1,1.1)";
        item2.guideConfig.icon =
          "https://fenghua.zhuneng.cn/imgForManager/static/index/portal-icon/mingzhuqrcode.png";
        item2.guideConfig.name = "小程序端二维码";
        ("");
      } else if (item2.isSelected == 1) {
        this.$refs[item2.guideConfig.code][index2].style.transition = "0.15s";
        this.$refs[item2.guideConfig.code][index2].style.transform =
          "scale(1.1,1.1)";
      }
    },
    outBox(item2, index2) {
      if (item2.guideConfig.key == "mingzhu" && item2.isSelected == 1) {
        this.$refs[item2.guideConfig.code][index2].style.transform =
          "scale(1,1)";
        item2.guideConfig.icon =
          "https://fenghua.zhuneng.cn/imgForManager/static/index/portal-icon/mingzhu.png";
        item2.guideConfig.name = "掌上奉化";
        ("");
      } else if (item2.isSelected == 1) {
        this.$refs[item2.guideConfig.code][index2].style.transform =
          "scale(1,1)";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  .black_layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    .box {
      width: 500px;
      height: 500px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .list {
      display: flex;
      box-sizing: border-box;
      align-content: center;
      justify-content: center;
      margin-bottom: 98px;

      .out_box {
        width: 260px;
        margin-right: 100px;
        position: relative;
        display: inline-block;

        .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.45);
          border-radius: 12px;
        }

        .item {
          width: 100%;
          padding: 47px 0 49px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.48);
          box-shadow: 0px 4px 18px 4px rgba(35, 35, 36, 0.23);
          border-radius: 12px;
          align-items: center;
          cursor: pointer;

          img {
            display: block;
            margin: 0 auto;
            width: 130px;
            height: 130px;
          }

          div {
            text-align: center;
            margin-top: 25px;
            font-size: 32px;
            font-weight: 600;
            color: #172147;
          }
        }
      }

      .out_box:nth-last-child(1) {
        margin-right: 0;
      }
    }
    .list:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .title {
    font-size: 52px;
    font-weight: 600;
    color: #172147;
    text-align: center;
    padding-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .goBack {
      cursor: pointer;
      position: absolute;
      right: 300px;
      width: 35px;
      height: 35px;
    }
  }
}
</style>
